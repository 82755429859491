import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Icon,
  Switch,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { Version, nanoid, VersionModel, VersionBundleModel } from '@sitecore-feaas/sdk'
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'

import { mdiOpenInNew } from '@mdi/js'
import { useLibrary, useSDK } from '../../../hooks/useData.js'
// @ts-ignore
import jsx from 'react-syntax-highlighter/dist/cjs/languages/prism/jsx'
// @ts-ignore
import markup from 'react-syntax-highlighter/dist/cjs/languages/prism/markup.js'
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light.js'
// @ts-ignore
import {
  DataSettings,
  getComponentSource,
  getStylesheetSource,
  parseComponentSource,
  parseStylesheetSource
} from '@sitecore-feaas/clientside/headless'
import * as inflection from 'inflection'
// @ts-ignore
import dark from 'react-syntax-highlighter/dist/cjs/styles/prism/material-oceanic.js'
import FieldsetField from '../../FieldsetField.js'
import { EnvironmentContext } from '../../providers/EnvironmentProvider.js'
import VersionEmbedOverview from './VersionEmbedOverview.js'
import { Select } from '../../Select.js'

SyntaxHighlighter.registerLanguage('jsx', jsx)
SyntaxHighlighter.registerLanguage('markup', markup)

export default function VersionEmbed({ componentId }: Partial<Version>) {
  const env = useContext(EnvironmentContext)
  const modes = ['React', 'React Server', 'WebComponent', 'DOM']
  const [modeIndex, setModeIndex] = useState(0)
  const mode = modes[modeIndex]
  const [autoUpdate, setAutoUpdate] = useState(true)
  const [typescript, setTypescript] = useState(true)
  const [preloading, setPreloading] = useState(true)
  const [compatability, setCompatability] = useState(true)
  const component = useLibrary('components').find((c) => c.id == componentId)
  const datasources = useSDK('datasources')
  const [code, setCode] = useState('')
  const [dataMethod, setDataMethod] = useState('bundled')
  const stagedVersions = component.getEmbeddableVersions('staged')
  const publishedVersions = component.getEmbeddableVersions('published')
  const [revision, setRevision] = useState<Version['status']>(publishedVersions.length ? 'published' : 'staged')

  const versions = revision === 'staged' ? stagedVersions : publishedVersions

  const versionOptions = [
    {
      label: 'Specific version',
      options: versions.filter((v) => !v.bundle)
    },
    {
      label: 'Bundle',
      options: versions.filter((v) => v.bundle)
    }
  ]

  const [versionId, setVersionId] = useState(() => versions[0]?.id)
  const version = versions.find((v) => v.id == versionId) ?? versions[0]
  const libraryId = useLibrary().id

  const usedDatasources = version.datasourceIds.map((id) => datasources.find((d) => d.id == id)).filter(Boolean)
  const hasContentHub = usedDatasources[0]?.type == 'contentHubOne'

  const wrapIntoFragment = (enable: boolean, code: string) => {
    return enable ? `<>\n  ${code.replace(/\n/g, '\n  ')}\n</>` : code
  }
  const label =
    'FEAAS' +
    (inflection
      .camelize((component.name + (version.id == 'responsive' ? '' : ' ' + version.name)).replace(/[^A-Za-z]+/g, '_'))
      .replace(/ /g, '') || 'Component')

  const instanceId = useMemo(() => nanoid(), [])
  // @ts-ignore
  const useLocalPageGenerator = window.Cypress || window.parent?.Cypress || localStorage.useLocalPageGenerator
  const cdnScript = useLocalPageGenerator
    ? `http://localhost:4000/api/scripts?@sitecore-feaas/clientside`
    : `https://cdn.jsdelivr.net/npm/@sitecore-feaas/clientside/+esm`
  const cdnScriptReact = useLocalPageGenerator
    ? `http://localhost:4000/api/scripts?@sitecore-feaas/clientside/react`
    : `https://cdn.jsdelivr.net/npm/@sitecore-feaas/clientside/react/+esm`

  let componentDatasourcesSettings = JSON.stringify({})
  let arg = JSON.stringify(null)
  if (usedDatasources.length && dataMethod != 'no-data') {
    const componentDatasourcesSettingsObject = usedDatasources.reduce((acc, datasource) => {
      return { ...acc, [datasource.id]: DataSettings.clean(datasource.settings) }
    }, {})
    componentDatasourcesSettings = JSON.stringify(
      false && usedDatasources.length == 1
        ? Object.values(componentDatasourcesSettingsObject)[0]
        : componentDatasourcesSettingsObject
    )
    const argObject = usedDatasources.reduce((acc, datasource) => {
      return { ...acc, [datasource.id]: datasource.sample }
    }, {})
    arg = JSON.stringify(false && usedDatasources.length == 1 ? Object.values(argObject)[0] : argObject)
  }
  const versionName =
    version.id == 'responsive'
      ? 'Responsive'
      : version.name.length > 60
      ? version.name.substring(0, 60) + '...'
      : version.name
  var cdnHostname = env.cdn
  var appHostname = env.frontend
  const componentURL = `${cdnHostname}/components/${libraryId}/${componentId}/${version.id}/${revision}.html`
  const componentShortURL = getComponentSource(parseComponentSource(componentURL))
  const stylesheetURL = `${cdnHostname}/styles/${libraryId}/published.css`
  const stylesheetShortURL = getStylesheetSource(parseStylesheetSource(stylesheetURL))

  const getCode = () => {
    if (mode == 'React') {
      return `
import * as FEAAS from '@sitecore-feaas/clientside/react'

// Collection: ${component.collection.name}
// Component:  ${component.name}
// Version:    ${versionName}
// URL:        ${appHostname}/libraries/${libraryId}/components/${component.id}
${!typescript ? `export function ${label}({ data }) {` : `export function ${label}({ data } : { data: any }) {`}
  return <FEAAS.Component ${preloading ? `\n    preload="true"` : ''}
    src="${componentShortURL}"
    data={data || ${componentDatasourcesSettings}}
  />
}


<${label}${dataMethod !== 'fetched' ? ` data={${arg}}` : ''} />
`
    }

    // React Server
    if (mode == 'React Server') {
      return `
import * as FEAAS from '@sitecore-feaas/clientside/react'

// Collection: ${component.collection.name}
// Component:  ${component.name}
// Version:    ${versionName}
// URL:        ${appHostname}/libraries/${libraryId}/components/${component.id}
${
  !typescript
    ? `export async function ${label}({ data }) {`
    : `export async function ${label}({ data } : { data: any }) {`
}
  return <FEAAS.ServerComponent ${preloading ? '\n    preload="true"' : ''}
    src="${componentShortURL}"
    ${`data={data ? JSON.stringify(data) : ${componentDatasourcesSettings}}`}
  />
}

{/* @ts-ignore Async Server Component */}
<${label}${dataMethod !== 'fetched' ? ` data={${arg}}` : ''} />
`
    }

    // DOM
    if (mode == 'DOM') {
      return `
import * as FEAAS from '@sitecore-feaas/clientside'

// Collection: ${component.collection.name}
// Component:  ${component.name}
// Version:    ${versionName}
// URL:        ${appHostname}/libraries/${libraryId}/components/${component.id}
${
  typescript
    ? `const render${label} = (element: HTMLElement, data: any) =>`
    : `const render${label} = (element, data) =>`
}
  FEAAS.renderComponent({
    src: '${componentShortURL}',
    data: data || {}
  }, element)

// Executing this multiple time will not cause extra network requests
FEAAS.loadStylesheet('${stylesheetShortURL}');

const element = render${label}(undefined, {});
document.body.appendChild(element);
FEAAS.DataSettings.fetch(${arg}).then((data) => render${label}(element, data))
`
    }

    // Web component
    return `
<script type="module">import '@sitecore-feaas/clientside'</script>
${preloading ? `<link rel="stylesheet" href="${stylesheetURL}" crossorigin="anonymous"  />` : ''}

<!--
Collection: ${component.collection.name}
Component:  ${component.name}
Version:    ${versionName}
URL:        ${appHostname}/libraries/${libraryId}/components/${componentId}
-->
<feaas-component
  src="${componentShortURL}"
  instance="${instanceId}"
  data='${arg.replace(/'/g, '&#39;')}'>
</feaas-component>
`
  }

  const createCodePen = () => {
    const div = document.createElement('div')
    const code = getCode()
      .trim()
      .replace(/^\/\/[^\n]+\n/gm, '')
      .replace(/<!--[\s\S]+-->\n/g, '')
      .trim()

    const bits = code.split(/\n\n\n/g)

    var html, js
    if (mode == 'WebComponent') {
      html = `${bits[0].replace(/<script>([\s\S]+?)<\/script>/gi, (m, j) => {
        js = j.replace(/^\s+/gm, '')
        return ''
      })}`.replace(
        `import '@sitecore-feaas/clientside'`,
        `import * as FEAAS from 'https://cdn.jsdelivr.net/npm/@sitecore-feaas/clientside/+esm'`
      )
    } else if (mode == 'React') {
      js = `import React from 'react';
import ReactDOM from 'react-dom';
${bits[0].replace(/export ((?:async )?function)/, '$1')}

const App = () => <>
${bits[1].replace(/^/gm, '  ')}
</>

ReactDOM.render(<App />, document.getElementById('root'));`
      html = `<div id="root"></div>`
    } else if (mode == 'React Server') {
      js = `import React from 'react';
import ReactDOM from 'react-dom';
${bits[0].replace(/export ((?:async )?function)/, '$1').replace("import 'server-only'\n", '')}

// JUST A DEMO: This is a simulation of how Server Side react works
FEAAS.ServerComponent({
  src: "${componentShortURL}",
  data: ${componentDatasourcesSettings}
}).then((${label}Sync) => {
const App = () => <>{${label}Sync}</>
ReactDOM.render(<App/>, document.getElementById('root'))
})
`
      html = `<div id="root"></div>`
    } else {
      js = code
    }
    js = (js || '').replace(/from '([^\']+)'/g, (m, name) => {
      return name == '@sitecore-feaas/clientside'
        ? `from '${cdnScript}'`
        : name == '@sitecore-feaas/clientside/react'
        ? `from '${cdnScriptReact}'`
        : `from 'https://cdn.jsdelivr.net/npm/${name}/+esm'`
    })
    const url = useLocalPageGenerator ? 'http://localhost:4000/api/page-generator' : 'https://codepen.io/pen/define'
    const val = JSON.stringify({
      title: component.name,
      html: html?.replace(/\n\n\n/g, '\n\n'),
      layout: 'right',
      js: js.replace(/\n\n\n/g, '\n\n'),
      editors: mode == 'WebComponent' ? '101' : '001',
      js_pre_processor: typescript ? 'typescript' : 'babel'
    })

    div.innerHTML = `<form action="${url}" method="POST" target=${
      useLocalPageGenerator ? 'embed-iframe' : '_blank'
    } style='position: fixed; opacity: 0; width: 0px; height: 0px; overflow-hidden; top: 0; left: 0;'>
      <input type="hidden" name="data" value=''>

      <input type="submit" value='Create New Pen with Prefilled Data'>
    </form>
    `
    ;(div.firstElementChild.querySelector('input[name="data"]') as HTMLInputElement).value = val
    document.body.appendChild(div)
    ;(div.firstElementChild as HTMLFormElement).submit()
    requestAnimationFrame(() => {
      document.body.removeChild(div)
    })
  }

  useEffect(() => {
    setCode(getCode)
  }, [revision, mode, autoUpdate, version.id, preloading, typescript, dataMethod])

  return (
    <VStack spacing={6} alignItems='stretch' maxWidth='100%'>
      {useLocalPageGenerator && (
        <iframe
          tabIndex={-1}
          style={{
            position: 'absolute',
            top: 60,
            left: 0,
            border: '1px solid red',
            //display: 'none',
            //background: 'rgba(255,255,255,1)',
            zIndex: 10,
            width: '100%',
            pointerEvents: 'none',
            height: 'calc(100% - 60px)'
          }}
          name='embed-iframe'
        ></iframe>
      )}
      <HStack alignItems={'center'} justifyContent={'space-between'} spacing={6}>
        <FieldsetField>
          <Select
            aria-label='Version'
            options={versionOptions}
            value={version}
            onChange={(value) => {
              setVersionId(value.id)
            }}
            getOptionLabel={(version) => version.name}
          />
        </FieldsetField>
        <ButtonGroupSwitch value={revision} onChange={setRevision}>
          <Button value='published' isDisabled={!publishedVersions.find((v) => v.id == version.id)}>
            Published
          </Button>
          <Button value='staged' isDisabled={!stagedVersions.find((v) => v.id == version.id)}>
            Staged
          </Button>
        </ButtonGroupSwitch>
        <ButtonGroupSwitch
          value={dataMethod}
          onChange={setDataMethod}
          visibility={usedDatasources.length > 0 ? 'visible' : 'hidden'}
        >
          <Button value='bundled'>Sample data</Button>
          <Button value='fetched'>
            {hasContentHub && (
              <img
                src='https://wwwsitecorecom.azureedge.net/-/media/sitecoresite/images/icons/cloud-icons/content-hub-one-horizontal-color-black-txt-v2.svg?md=20221209T173334Z&la=en&hash=5C2D36C19A12069343C5DC70776D52A7'
                style={{
                  objectFit: 'cover',
                  objectPosition: 'left',
                  width: '24px',
                  height: '24px',
                  marginRight: '4px'
                }}
              />
            )}
            Fetch
          </Button>
          <Button value='no-data'>No data</Button>
        </ButtonGroupSwitch>
      </HStack>
      {version.bundle && false && <VersionEmbedOverview component={component} bundleId={version.id} />}
      <HStack alignItems={'center'} justifyContent={'space-between'} spacing={6}>
        {false && (
          <FieldsetField
            label='Auto-update'
            extraProps={{
              visibility: version.id != 'responsive' ? 'visible' : 'hidden',
              width: 'auto',
              display: 'flex',
              whiteSpace: 'nowrap',
              justifyContent: 'center',
              css: `.chakra-form__label {margin: 0 !important; line-height: 26px; padding-right: 8px;}`
            }}
          >
            <Switch
              isChecked={autoUpdate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAutoUpdate(e.target.checked)}
              size='lg'
            />
          </FieldsetField>
        )}
        <Tooltip
          isDisabled={mode == 'WebComponent'}
          label={
            typescript
              ? `Enabled: Code is generated with type signatures for arguments and functions`
              : `Disabled: Code is generated without type signatures, so typescript compilation is not required`
          }
        >
          <FieldsetField
            isDisabled={mode == 'WebComponent'}
            label='Typescript'
            extraProps={{
              width: 'auto',
              display: 'flex',
              whiteSpace: 'nowrap',
              justifyContent: 'center',
              css: `.chakra-form__label {margin: 0 !important; line-height: 26px; padding-right: 8px;}`
            }}
          >
            <Switch
              isChecked={mode == 'WebComponent' ? false : typescript}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setTypescript(e.target.checked)}
              size='lg'
            />
          </FieldsetField>
        </Tooltip>
        <Tooltip
          label={
            preloading
              ? `Enabled: All resources are loaded in parallel with the page to ensure fastest loading speed`
              : `Disabled: SDK JS is loaded first, which then loads the HTML/CSS files slightly increasing initial loading time`
          }
        >
          <FieldsetField
            label='Preload resources'
            extraProps={{
              width: 'auto',
              display: 'flex',
              whiteSpace: 'nowrap',
              justifyContent: 'center',
              css: `.chakra-form__label {margin: 0 !important; line-height: 26px; padding-right: 8px;}`
            }}
          >
            <Switch
              isDisabled={mode == 'DOM' || mode == 'WebComponent'}
              isChecked={mode == 'DOM' || mode == 'WebComponent' ? false : preloading}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPreloading(e.target.checked)}
              size='lg'
            />
          </FieldsetField>
        </Tooltip>
      </HStack>
      <VStack flexShrink={1} minHeight={1} display='flex' alignItems={'stretch'}>
        <Tabs index={modeIndex} onChange={setModeIndex}>
          <TabList>
            <Tab>React</Tab>
            <Tab>React Server</Tab>
            <Tab>Web Component</Tab>
            <Tab>DOM API</Tab>
          </TabList>
        </Tabs>

        <SyntaxHighlighter
          id='generated-code'
          language={mode == 'WebComponent' ? 'markup' : 'jsx'}
          style={dark}
          customStyle={{ fontSize: '14px', width: '100%', flexShrink: '1', overflow: 'auto', marginTop: '1px' }}
        >
          {code.trim().replace(/\n\n\n/g, '\n\n')}
        </SyntaxHighlighter>
      </VStack>

      <HStack position={'absolute'} bottom={6} right={'170px'} width='200px' zIndex={1} background={'white'}>
        <Button
          variant='secondary'
          leftIcon={
            <Icon>
              <path d={mdiOpenInNew} />
            </Icon>
          }
          onClick={() => createCodePen()}
        >
          Preview on Codepen
        </Button>
      </HStack>
    </VStack>
  )
}
